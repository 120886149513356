<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Invoice template"
                rules="required"
              >
                <MultiSelectInput
                  :required="true"
                  v-model="invoiceTemplateId"
                  :options="documentServices.data"
                  :key="invoiceTemplateId"
                  :multiple="false"
                  :textLabel="$t('Invoice template')"
                  label="name"
                  trackBy="id"
                  :error="errors.invoiceTemplateId"
                  moduleName="documentService"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Invoice Correction template"
                rules="required"
              >
                <MultiSelectInput
                  :required="true"
                  v-model="invoiceCorrectionTemplateId"
                  :options="documentServices.data"
                  :key="invoiceCorrectionTemplateId"
                  :multiple="false"
                  :textLabel="$t('Invoice Correction template')"
                  label="name"
                  trackBy="id"
                  :error="errors.invoiceCorrectionTemplateId"
                  moduleName="documentService"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Invoice Storno template"
                rules="required"
              >
                <MultiSelectInput
                  :required="true"
                  v-model="invoiceStornoTemplateId"
                  :options="documentServices.data"
                  :key="invoiceStornoTemplateId"
                  :multiple="false"
                  :textLabel="$t('Invoice Storno template')"
                  label="name"
                  trackBy="id"
                  :error="errors.invoiceStornoTemplateId"
                  moduleName="documentService"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="createDocument"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Template") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("documentService", ["documentServices", "count"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Document Assignment"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      modelData: {},
      invoiceTemplateId: "",
      invoiceCorrectionTemplateId: "",
      invoiceStornoTemplateId: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("documentService/list", {
      limit_start: 0,
      limit_count: 25,
    });

    const response = await this.$store.dispatch(
      "invoices/documentAssignmentList"
    );
    this.modelData = response?.data?.data ?? {};

    try {
      const response = await this.$store.dispatch(
        "documentService/show",
        this.modelData.invoiceTemplateId
      );
      this.invoiceTemplateId = response?.data ?? "";
    } catch (e) {}
    try {
      const response = await this.$store.dispatch(
        "documentService/show",
        this.modelData.invoiceCorrectionTemplateId
      );
      this.invoiceCorrectionTemplateId = response?.data ?? "";
    } catch (e) {}
    try {
      const response = await this.$store.dispatch(
        "documentService/show",
        this.modelData.invoiceStornoTemplateId
      );
      this.invoiceStornoTemplateId = response?.data ?? "";
    } catch (e) {}
  },
  methods: {
    async createDocument() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("invoices/saveDocumentAssignment", {
            invoiceTemplateId: this.invoiceTemplateId?.id,
            invoiceCorrectionTemplateId: this.invoiceCorrectionTemplateId?.id,
            invoiceStornoTemplateId: this.invoiceStornoTemplateId?.id,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
